<template>
  <div>
    <ViewTemplateWithTable :panel="panel" :templateList="templateList">
      <div slot="content-buttons">
        <Button
          _key="createDocument"
          title="Documento"
          classIcon="fas fa-plus-circle"
          type="primary"
          size="small"
          eventName="createDocument"
          :clicked="createDocument"
        />
      </div>
    </ViewTemplateWithTable>
  </div>
</template>

<script>
import ViewTemplateWithTable from "@nixweb/nixloc-ui/src/component/template/ViewTemplateWithTable";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";

import { mapGetters, mapMutations } from "vuex";

export default {
  name: "DocumentListView",
  components: {
    ViewTemplateWithTable,
    Button,
  },
  data() {
    return {
      panel: {
        module: "Adm",
        title: "Documento",
        showFilter: true,
        showSearch: true,
        showButtons: true,
      },
      templateList: {
        urlGetApi: "/api/v1/adm/document/get-all",
        urlDeleteAllApi: "api/v1/adm/document/delete",
        headerTable: [
          {
            field: "identification",
            title: "Identificação",
            type: "link",
            routeName: "documentUpdate",
            iconSearch: true,
          },
          {
            field: "name",
            title: "Arquivo",
            type: "text",
            iconSearch: false,
          },
          {
            field: "modulePortuguese",
            title: "Módulo",
            type: "text",
            iconSearch: false,
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters("generic", ["showModal", "event"]),
  },
  methods: {
    ...mapMutations("generic", ["openModal", "removeLoading"]),
    createDocument() {
      let self = this;
      setTimeout(function () {
        self.$router.push({
          name: "documentCreate",
        });
      }, 300);
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "updateDocument") {
          let self = this;
          setTimeout(function () {
            self.$router.push({
              name: "documentUpdate",
              params: { id: event.data.id },
            });
            self.removeLoading([event.data.id]);
          }, 300);
        }
      },
      deep: true,
    },
  },
};
</script>
